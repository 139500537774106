import { LOCATION_FIELD_NAMES } from 'components/formFields/overlayVariant/fields.keys';
import { FormFieldDescription, SelectOption } from 'components/formFields/overlayVariant/fields.types';
import { DonorInfo } from 'types/donor';
import DonationWorkflowType from 'types/workflow';
import { getCountries, getStates } from 'utils/countries';

import {
  anonymousDonorField,
  donorFormFields,
  KEYS,
  occupationFields,
  RETIRED_FIELD_OPTIONAL_KEYS,
} from './keys';

interface BuildFormFieldParams {
  anonymousAllowed: boolean;
  areEmployerDetailsRequired?: boolean;
  isCharityCommunicationEnabled?: boolean;
  isPCDEnabled?: boolean;
  countryDisabled?: boolean;
  formValues: DonorInfo;
  workflowType: DonationWorkflowType;
}

const disableField = field => ({
  ...field,
  isRequired: false,
  isDisabled: true,
});

export const buildFormFields = ({
  anonymousAllowed,
  isPCDEnabled = false,
  isCharityCommunicationEnabled = true,
  areEmployerDetailsRequired = false,
  formValues,
  workflowType,
  countryDisabled = false,
}: BuildFormFieldParams): FormFieldDescription[] => {
  const fieldsToBeUsed = [...donorFormFields];

  if (countryDisabled) {
    const countryField = fieldsToBeUsed.find(({ name }) => name === LOCATION_FIELD_NAMES.COUNTRY);
    if (countryField) {
      countryField.isDisabled = true;
    }
  }

  if (areEmployerDetailsRequired) {
    const isRetired = formValues.isDonorRetired;
    const disabledOccupationFields = occupationFields.map((field) => {
      if (RETIRED_FIELD_OPTIONAL_KEYS.includes(field.name)) {
        return disableField(field);
      }

      return field;
    });

    const occupationFieldsToBeInserted = isRetired ? disabledOccupationFields : occupationFields;

    const hasJoinMailCheckboxField = Boolean(fieldsToBeUsed.find(field => field.name === KEYS.COMMUNICATION_ALLOWED_KEY));
    const occupationFieldsInsertionIndex = hasJoinMailCheckboxField ? fieldsToBeUsed.length - 1 : fieldsToBeUsed.length;
    fieldsToBeUsed.splice(occupationFieldsInsertionIndex, 0, ...occupationFieldsToBeInserted);
  }

  const preparedFields = fieldsToBeUsed
    .filter((field) => {
      const isCommunicationField = field.name === KEYS.COMMUNICATION_ALLOWED_KEY;
      const shouldRemoveCommunicationField = formValues.isAnonymous || isPCDEnabled || !isCharityCommunicationEnabled;
      if (isCommunicationField && shouldRemoveCommunicationField) {
        return false;
      }

      const isHiddenForField = field.hiddenFor?.includes(workflowType);

      if (isHiddenForField) {
        return false;
      }

      return true;
    })
    .map((field: FormFieldDescription) => {
      if (field.name === LOCATION_FIELD_NAMES.COUNTRY) {
        return { ...field, options: getCountries() as SelectOption[] };
      }

      if (field.name === LOCATION_FIELD_NAMES.STATE) {
        return { ...field, options: getStates(formValues[LOCATION_FIELD_NAMES.COUNTRY_ID]) as SelectOption[] };
      }

      return field;
    });

  if (!anonymousAllowed) {
    return preparedFields;
  }

  if (formValues.isAnonymous) {
    const changedFields = preparedFields.map(disableField);

    return [
      anonymousDonorField,
      ...changedFields,
    ];
  }

  return [
    anonymousDonorField,
    ...preparedFields,
  ];
};

export default buildFormFields;
