import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  container: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    height: 50,
    marginRight: 25,
  },
  imageContainer: {
    width: 50,
    marginRight: 10,
  },
  header: {
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
  },
});

export default useStyles;
