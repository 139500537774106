import { environmentConfig } from 'environment/environment.config';

import { OrganizationInfo } from 'types/organization';

import { AnyReduxState } from '../state.types';

export type OrganizationReduxState = AnyReduxState & {
  organization: OrganizationInfo;
  isFetching: boolean;
  error: Record<string, string> | null;
}

export const initialState = (): OrganizationReduxState => ({
  organization: {
    allowsAnon: false,
    areNotesEnabled: false,
    areTributesEnabled: false,
    id: 0,
    logo: '',
    name: '',
    disabled: false,
    areFiatDonationsEnabled: true,
    areCryptoDonationsEnabled: true,
    areStockDonationsEnabled: true,
    areDafDonationsEnabled: false,
    areRecurringDonationsEnabled: true,
    areApplePayEnabled: false,
    areACHDonationsEnabled: false,
    isCharityCommunicationEnabled: true,
    isPCDEnabled: environmentConfig.isPCDEnabled,
    areEmployerDetailsRequired: false,
    fundsDesignations: null,
    achFee: null,
    fiatFee: null,
    shift4PublicKey: null,
    shift4MerchantId: null,
    areFeesCoveredByDefaultEnabled: false,
    countryCodeIso2: '',
    payoutCurrency: null,
    isSupportPanelEnabled: false,
    customSupportEmail: null,
  },
  isFetching: true,
  error: null,
});
