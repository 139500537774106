import checkIsFiatDonationType from 'utils/workflow';

import { DonationWorkflowType } from '../types/workflow';
import { KEYS } from './keys';
import { PoweredByBrand, PoweredByBrandType } from './types';

export interface GetBrandParams {
  workflowType: DonationWorkflowType;
  isPCDEnabled: boolean;
}

export const getSupportEmail = (customSupportEmail: string | null): string => (customSupportEmail ?? KEYS.DEFAULT_SUPPORT_EMAIL);

export const hasCustomSupportEmail = (customSupportEmail: string | null): boolean => {
  const supportEmail = getSupportEmail(customSupportEmail);
  return supportEmail !== KEYS.DEFAULT_SUPPORT_EMAIL;
};

export const trimInCenter = (message: string, maxSymbols: number = 30, trimToSymbols: number = 30): string => {
  if (message.length <= maxSymbols) {
    return message;
  }

  const ellipsis = '...';
  const firstPart = message.substring(0, trimToSymbols / 2);
  const lastPart = message.substring(message.length - (trimToSymbols / 2), message.length);
  return [firstPart, ellipsis, lastPart].join('');
};

export const getBrand = ({
  workflowType,
  isPCDEnabled,
}: GetBrandParams): PoweredByBrandType => {
  if (isPCDEnabled) {
    return PoweredByBrand.Gemini;
  }

  const isFiatDonation = checkIsFiatDonationType(workflowType);

  if (isFiatDonation) {
    return PoweredByBrand.Card;
  }

  return PoweredByBrand.Crypto;
};

export default getBrand;
