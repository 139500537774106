import React, { ReactNode, useMemo, useState } from 'react';
import { Paper } from '@mui/material';

import OptionallyVisible from 'components/optionallyVisible';
import { OrganizationInfo } from 'types/organization';
import DonationWorkflowType, {
  CreditCardDonationWorkflowStep,
  CryptoDonationWorkflowStep,
  WidgetVariant,
} from 'types/workflow';
import { getUsedDisplayVariant } from 'utils/componentSwitch';

import { DisabledPage } from '../disabled';
import { KEYS, LABELS } from '../keys';
import { PoweredByBrand, PoweredByBrandType } from '../types';
import getBrand from '../utils';
import Footer from './footer';
import { Header } from './header';
import useStyles from './styles';
import SupportDialog from './supportDialog';

interface LayoutProps {
  children: ReactNode;
  organization: OrganizationInfo;
  step: CryptoDonationWorkflowStep | CreditCardDonationWorkflowStep | null;
  steps: {
    title: string;
    key: CryptoDonationWorkflowStep;
  }[];
  workflowType: DonationWorkflowType;
}

export const Layout = ({
  children,
  organization,
  step,
  steps,
  workflowType,
}: LayoutProps) => {
  const isOverlay = getUsedDisplayVariant() === WidgetVariant.Overlay;
  const [isSupportDialogOpen, setSupportDialogOpen] = useState<boolean>(false);
  const { classes } = useStyles({ isOverlay });

  const toggleSupportDialogOpenState = (nextValue: boolean) => () => {
    setSupportDialogOpen(nextValue);
  };

  const brand: PoweredByBrandType = useMemo(() => getBrand({
    workflowType,
    isPCDEnabled: organization.isPCDEnabled,
  }), [workflowType, organization.isPCDEnabled]);

  if (organization?.disabled) {
    return <DisabledPage organization={organization} />;
  }

  return (
    <div className={classes.outerContainer}>
      <OptionallyVisible visible={!organization.isPCDEnabled}>
        <div className={classes.rootLogos}>
          <img src={KEYS.LOGO[PoweredByBrand.Crypto]} alt={LABELS.ALT[PoweredByBrand.Crypto]} />
          <div className={classes.rootLogosSeparator} />
          <img src={KEYS.LOGO[PoweredByBrand.Card]} alt={LABELS.ALT[PoweredByBrand.Card]} />
        </div>
      </OptionallyVisible>
      <div className={classes.innerContainer}>
        <SupportDialog
          workflowType={workflowType}
          isSupportDialogOpen={isSupportDialogOpen}
          closeSupportDialog={toggleSupportDialogOpenState(false)}
          customSupportEmail={organization.customSupportEmail}
        />
        <Paper className={classes.paper}>
          <Header organization={organization} />
          <div className={classes.contentWrapper}>
            {children}
          </div>
        </Paper>
        <Footer
          brand={brand}
          openSupportDialog={toggleSupportDialogOpenState(true)}
          isSupportPanelEnabled={organization.isSupportPanelEnabled}
        />
      </div>
    </div>
  );
};

export default Layout;
