import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  icon: {
    maxWidth: 150,
  },
  title: {
    margin: 12,
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 25,
    lineHeight: '35px',
    color: theme.palette.text.primary,
  },
  label: {
    fontFamily: 'Noto sans',
    fontWeight: 400,
    fontSize: 16,
    margin: 0,
    lineHeight: '28px',
    marginBottom: 25,
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: 'center',
    color: theme.palette.text.primary,
    whiteSpace: 'pre-wrap',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 360,
    minWidth: 330,
    width: '100%',
  },
  backButton: {
    flex: 1,
    height: 50,
    fontSize: 16,
    borderRadius: theme.shape.borderRadius,
    padding: '11px 41px 13px',
    color: theme.palette.primary.main,
    lineHeight: '24px',
    textTransform: 'none',
    background: theme.palette.secondary.main,
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
}));

export default useStyles;
