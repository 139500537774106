import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import { KEYS } from './keys';
import {
  SearchableDropdown,
  SearchableDropdownProps,
} from './searchableDropdown';

interface SearchableDropdownWithFetchProps<T> extends SearchableDropdownProps<T> {
  fetchData: (args: {
    page: number;
    search: string;
  }) => Promise<any[]>;
  pageSize: number;
}

export const SearchableDropdownWithFetch = <T extends object>({
  items,
  onChange,
  selected,
  disabled = false,
  error,
  helperText,
  fetchData,
  pageSize,
  getOptionLabel,
  ComboboxProps = {},
}: SearchableDropdownWithFetchProps<T>) => {
  const [page, setPage] = useState<number>(1);
  const [canLoadNextPage, setCanLoadNextPage] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    onUpdate();
  }, [searchValue, page]);

  const onUpdate = async () => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);

      const records = await fetchData({
        page,
        search: searchValue,
      });

      setLoading(false);
      setCanLoadNextPage(records.length >= pageSize);
    } catch (e) {
      setLoading(false);
      setCanLoadNextPage(false);
    }
  };

  const loadNextPage = () => {
    if (!canLoadNextPage) {
      return;
    }

    setPage(page + 1);
  };

  const onSearchChanged = (value: string) => {
    setPage(1);
    setSearchValue(value);
  };

  const debouncedSearchHandler = useCallback(debounce((value) => {
    onSearchChanged(value);
  }, KEYS.DEBOUNCE_TIMEOUT), []);

  const onInputChange = (
    _event: SyntheticEvent<Element, Event>,
    rawValue: string,
  ) => {
    debouncedSearchHandler(rawValue ?? '');
  };

  const getCanLoadNextPage = useCallback((listboxNode: React.SyntheticEvent['currentTarget']) => {
    const differenceFromBottom = listboxNode.scrollHeight - (listboxNode.scrollTop + listboxNode.clientHeight);

    return !loading
      && differenceFromBottom < KEYS.OFFSET_BOTTOM_NEXT_PAGE
      && canLoadNextPage;
  }, [loadNextPage, loading]);

  const onScrollThrottle = throttle((listboxNode: React.SyntheticEvent['currentTarget']) => {
    if (getCanLoadNextPage(listboxNode)) {
      loadNextPage();
    }
  }, KEYS.SCROLL_THROTTLE_TIMEOUT);

  const onScroll = (event: React.SyntheticEvent) => {
    onScrollThrottle(event.currentTarget);
  };

  const generalProps = {
    items,
    onChange,
    selected,
    disabled,
    error,
    helperText,
    getOptionLabel,
  };

  return (
    <SearchableDropdown
      {...generalProps}
      ComboboxProps={{
        onInputChange,
        ListboxProps: {
          onScroll,
        },
        loading,
        ...ComboboxProps,
      }}
    />
  );
};

export default SearchableDropdownWithFetch;
