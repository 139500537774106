import React from 'react';
import { Typography } from '@mui/material';

import Icon from '../icon';
import OptionallyVisible from '../optionallyVisible';
import useStyles from './styles';

interface SectionHeaderProps {
  text: string;
  icon?: string;
}

export const SectionHeader = ({
  text,
  icon = '',
}: SectionHeaderProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <OptionallyVisible visible={Boolean(icon)}>
        <div className={classes.imageContainer}>
          <Icon name={icon} className={classes.image} />
        </div>
      </OptionallyVisible>
      <Typography variant="body1" className={classes.header}>{text}</Typography>
    </div>
  );
};

SectionHeader.defaultProps = {
  icon: '',
};

export default SectionHeader;
