import React, {
  Ref, useContext, useEffect, useMemo, useState,
} from 'react';
import { Box, Container } from '@mui/material';
import {
  Form, Formik, FormikErrors, FormikProps,
  FormikValues,
} from 'formik';
import * as Yup from 'yup';

import { Button } from 'components/button/button';
import { CaptchaContext } from 'components/captcha/captchaContext';
import CaptchaTermsAndConditions from 'components/captcha/termsAndConditions';
import { FormField } from 'components/formFields/overlayVariant/formField';
import { SetFieldValueFunction } from 'components/formFields/types';
import Loading from 'components/loading';
import OptionallyVisible from 'components/optionallyVisible';
import PageHeader from 'components/pageHeader/pageHeader';
import EmailDialog from 'pages/common/donate/overlayVariant/emailDialog';
import { DonorInfo } from 'types/donor';
import { OrganizationInfo } from 'types/organization';
import DonationWorkflowType from 'types/workflow';

import { KEYS, LABELS, RETIRED_FIELD_OPTIONAL_KEYS } from './keys';
import { useStyles } from './styles';
import buildFormFields from './utils';

const fieldSchemas = {
  email: Yup.string().email(LABELS.INVALID_EMAIL),
};

interface DonorDetailsProps {
  donor: DonorInfo;
  defaultEmptyState: DonorInfo;
  organization: OrganizationInfo;
  updateValue: (field: string, value: any) => void;
  goBack: () => void;
  submitPledge: (donor: DonorInfo, captchaToken: string | null) => void;
  onProcess: () => void;
  workflowType: DonationWorkflowType;
  isSubmitting: boolean;
  fallbackAddressUsed: boolean;
  pledgeError: Record<string, string> | null;
  isRecurringDonation: boolean;
  countryDisabled: boolean;
}

export const DonorInfoScreen = ({
  donor,
  defaultEmptyState,
  goBack,
  organization,
  updateValue,
  submitPledge,
  workflowType,
  isSubmitting: isSubmittingState,
  fallbackAddressUsed,
  pledgeError,
  isRecurringDonation,
  countryDisabled,
  onProcess,
}: DonorDetailsProps) => {
  const { classes, cx } = useStyles();
  const [errors, setErrors] = useState({});
  const [isEmailPromptVisible, setIsEmailPromptVisible] = useState<boolean>(false);
  const { execute } = useContext(CaptchaContext);
  const formRef: Ref<FormikProps<FormikValues>> | undefined = React.createRef();
  const shouldShowEmailPrompt = (donor.isAnonymous || fallbackAddressUsed) && !organization.isPCDEnabled;
  const formFields = useMemo(() => buildFormFields({
    anonymousAllowed: organization.allowsAnon && !isRecurringDonation,
    isPCDEnabled: organization.isPCDEnabled,
    isCharityCommunicationEnabled: organization.isCharityCommunicationEnabled,
    areEmployerDetailsRequired: organization.areEmployerDetailsRequired,
    formValues: donor,
    workflowType,
    countryDisabled,
  }), [organization.allowsAnon, donor, workflowType, countryDisabled]);
  const formatters = useMemo(() => formFields.reduce((acc, formField) => ({
    ...acc,
    [formField.name]: formField.formatter || ((value: string) => value),
  }), {}), [formFields]);

  const schemaObject = formFields.reduce((schema, item) => {
    if (!item.isRequired) {
      return schema;
    }

    return {
      ...schema,
      [item.name]: (fieldSchemas[item.type] || Yup.string()).required(LABELS.REQUIRED),
    };
  }, {});

  useEffect(() => {
    if (!formRef.current || !pledgeError) {
      return;
    }

    const errorsWithFormattedKeys = Object.entries(pledgeError).reduce((acc, [key, value]) => {
      const formattedKey = key.charAt(0).toLowerCase() + key.slice(1);
      return ({ ...acc, [formattedKey]: value });
    }, {});

    formRef.current.setErrors(errorsWithFormattedKeys);
  }, [pledgeError, formRef.current]);

  const validationSchema = Yup.object().shape(schemaObject);

  const containerClasses = cx([
    classes.formContainer,
    !organization.allowsAnon ? classes.anonDisabled : '',
  ]);

  const getFieldHandler = (setFieldValue: SetFieldValueFunction, resetForm) => (
    fieldName: string,
    rawValue: any,
    shouldValidate?: boolean,
  ) => {
    const shouldResetForm = fieldName === KEYS.ANONYMOUS_FIELD_NAME && rawValue === true;
    const shouldResetOcupationFormValues = fieldName === KEYS.IS_RETIRED_FIELD_NAME && rawValue === true;
    if (shouldResetForm) {
      resetForm({ values: defaultEmptyState });
    }

    if (shouldResetOcupationFormValues) {
      RETIRED_FIELD_OPTIONAL_KEYS.forEach((fieldName) => {
        const defaultValue = defaultEmptyState[fieldName];
        setFieldValue(fieldName, defaultValue, shouldValidate);
        updateValue(fieldName, defaultValue);
      });
    }

    const formatter = formatters[fieldName];
    const value = formatter ? formatter(rawValue) : rawValue;

    setFieldValue(fieldName, value, shouldValidate);
    updateValue(fieldName, value);
  };

  const isSubmitDisabled = (values, isValid) => {
    const isDisabledAnon = !organization.allowsAnon && donor.isAnonymous;
    const isDisabledWhenNotValid = !donor.isAnonymous && donor.email && !isValid && !donor.isDonorRetired;
    return Boolean(isDisabledAnon || isDisabledWhenNotValid);
  };

  const validatePledge = async (
    values: DonorInfo,
    validateForm: (values?: any) => Promise<FormikErrors<any>>,
    setTouched,
  ) => {
    const validationResult = await validateForm(values);
    setTouched(validationResult);
    setErrors(validationResult);

    return Object.keys(validationResult);
  };

  const getCaptchaToken = async () => {
    if (workflowType !== DonationWorkflowType.Crypto) {
      return null;
    }

    const captchaToken = await execute();

    return captchaToken;
  };

  const handleSubmitPledge = (
    values: DonorInfo,
    validateForm: (values?: any) => Promise<FormikErrors<any>>,
    setTouched,
  ) => async () => {
    const erroredFields = await validatePledge(values, validateForm, setTouched);
    if (erroredFields.length) {
      return;
    }

    onProcess();
    if (shouldShowEmailPrompt) {
      setIsEmailPromptVisible(true);
      return;
    }

    const captchaToken = await getCaptchaToken();
    submitPledge(values, captchaToken);
  };

  const handleModalSubmitWithEmail = async (
    { receiptEmail },
  ) => {
    const captchaToken = await getCaptchaToken();

    submitPledge({ ...donor, receiptEmail }, captchaToken);
    setIsEmailPromptVisible(false);
  };

  const handleModalSubmit = async () => {
    const captchaToken = await getCaptchaToken();

    submitPledge({ ...donor, receiptEmail: undefined }, captchaToken);
    setIsEmailPromptVisible(false);
  };

  if (isEmailPromptVisible) {
    return (
      <Container className={classes.donationContainerWrapper}>
        <EmailDialog
          goBack={goBack}
          onSubmit={handleModalSubmitWithEmail}
          toggleModalSubmitOpen={handleModalSubmit}
          workflowType={workflowType}
        />
      </Container>
    );
  }

  if (isSubmittingState) {
    return <Loading text={LABELS.SUBMITTING_TEXT} />;
  }

  return (
    <div className={classes.scrollContainer}>
      <PageHeader label={LABELS.PAGE_TITLE} withBackButton onGoBack={goBack} />
      {/* @ts-ignore */}
      <Formik
        innerRef={formRef}
        initialValues={donor}
        validationSchema={validationSchema}
        errors={errors}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          isValid,
          setFieldTouched,
          validateForm,
          setTouched,
          resetForm,
        }) => (
          <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <div className={containerClasses}>
              {formFields.map(item => (
                <FormField
                  key={item.name}
                  item={item}
                  values={values as DonorInfo}
                  hasPledgeError={Boolean(pledgeError)}
                  isSubmitting={isSubmitting}
                  setFieldValue={getFieldHandler(setFieldValue, resetForm)}
                  setFieldTouched={setFieldTouched}
                />
              ))}
            </div>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <Button
                onClick={handleSubmitPledge(values as DonorInfo, validateForm, setTouched)}
                disabled={isSubmitDisabled(values, isValid) || isSubmitting || isSubmittingState}
                className={classes.nextButton}
              >
                {LABELS.RIGHT_BUTTON_TEXT}
              </Button>
            </Box>
            <OptionallyVisible visible={workflowType === DonationWorkflowType.Crypto}>
              <CaptchaTermsAndConditions className={classes.captchaTerms} />
            </OptionallyVisible>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DonorInfoScreen;
