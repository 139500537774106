import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import changeStep from 'state/workflow/changeStep';
import setError from 'state/workflow/setError';
import { DonorInfo } from 'types/donor';
import { FundraiserId } from 'types/fundraiser';
import { OrganizationInfo } from 'types/organization';
import { DonationPledge } from 'types/pledge';
import { DafDonationWorkflowStep } from 'types/workflow';
import detectDocumentOrigin from 'utils/detectDocumentOrigin';
import removeNullValues from 'utils/objects/removeNullValues';
import getApiUserUuid from 'utils/queryParams';

import postDonationInfoFail from '../postDonationInfo.fail';
import postDonationInfoStart from '../postDonationInfo.start';
import postDonationInfoSuccess from '../postDonationInfo.success';

export const errorScreenTitles = {
  INSUFFICIENT_BALANCE: 'Insufficient Balance',
  default: 'Error!',
};

export const errorMessages = {
  INSUFFICIENT_BALANCE: 'Your donation amount exceeds your available balance. Please try a lower amount.',
  default: 'Your donation could not be processed.\nPlease try again',
};

export interface PostDonationInfoParams {
  organization: OrganizationInfo;
  workflowSessionId: string;
  donor: DonorInfo;
  pledge: DonationPledge;
  pledgeAmount: number;
  fundraiserId: FundraiserId;
  coverTransactionFees: boolean;
  campaignId?: string;
  externalId?: string | null;
}

const prepareDonorInfo = (organization: OrganizationInfo, donor: DonorInfo) => (
  donor.isAnonymous
    ? removeNullValues({
      Anonim: true,
      receiptEmail: donor.receiptEmail || null,
    })
    : removeNullValues({
      'Address 1': donor.address1 || '',
      'Address 2': donor.address2 || '',
      Anonim: donor.isAnonymous,
      City: donor.city || '',
      Country: donor.country || '',
      Email: donor.email || '',
      'First name': donor.firstName || '',
      'Last name': donor.lastName || '',
      State: donor.state || '',
      Zipcode: donor.zipCode || '',
      isCharityCommunicationAllowed: donor.isCharityCommunicationAllowed,
      isDonorRetired: organization.areEmployerDetailsRequired ? donor?.isDonorRetired : null,
      employer: organization.areEmployerDetailsRequired ? donor?.employer : null,
      occupation: organization.areEmployerDetailsRequired ? donor?.occupation : null,
    }));

export const postDonationInfo = ({
  organization,
  workflowSessionId,
  donor,
  pledge,
  pledgeAmount,
  fundraiserId,
  campaignId,
  coverTransactionFees,
  externalId,
}: PostDonationInfoParams) => async (dispatch: Dispatch<any>) => {
  dispatch(postDonationInfoStart.createAction());

  const apiUserUuid = getApiUserUuid();
  const origin = await detectDocumentOrigin;
  const body = removeNullValues({
    ...prepareDonorInfo(organization, donor),
    origin,
    workflowSessionId,
    pledgeAmount,
    apiUserUuid,
    fundraiserId,
    coverTransactionFees,
    Notes: pledge.notes,
    honoreeEmail: pledge.notifyHonoree ? pledge.honoreeEmail : null,
    honoreeName: pledge.honoreeName,
    campaignId: campaignId || null,
    externalId: externalId || null,
  });

  try {
    const response = await API.post(Endpoints.chariot.byOrganizationId(organization.id), body);

    if (!response.data?.isSuccess) {
      throw new Error(errorMessages.default);
    }

    dispatch(postDonationInfoSuccess.createAction());
    dispatch(changeStep.createAction(DafDonationWorkflowStep.Success));
  } catch (e: any) {
    const errorMessage = errorMessages[e.code] || e.message || errorMessages.default;
    dispatch(postDonationInfoFail.createAction({ errorMessage }));
    dispatch(setError.createAction(errorMessage, errorScreenTitles[e.code]));
  }
};

export default postDonationInfo;
